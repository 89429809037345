// Announcement

function initCookies() {
    var cookie = $('.cookie-notice');

    if (Cookies.get('CookieNotice')) {
        cookie.hide();
    } else {
        cookie.show();
        $('.cookie-notice__close').click(function () {
            Cookies.set('CookieNotice', 'accepted', {
                expires: 365
            });
            cookie.hide();
        });
    }
    
};

function deferCookies(method) {
    if (window.jQuery) {
        //console.log('All loaded and ready for cookie notice cookies');
        method();
    }
    else {
        setTimeout(function () { defer(method) }, 50);
    }
}

deferCookies(function () {
    initCookies();
});