$(function(){
    var header = $('header');

    $(window).on('scroll load resize', function() {
            if ($(this).scrollTop() >= 10) { 
                header.addClass('header--sticky');
            }
            else {
                header.removeClass('header--sticky');
            }
        });




})


