$(function(){
    var menu_trigger = $('.nav__toggle');
    var nav = $('.nav');
    var body = $('body');
    
    

    menu_trigger.click(function () {
        $(this).toggleClass('nav__toggle--active');
        nav.toggleClass('nav--active');
        body.toggleClass('overflow--hidden');
        if ($(this).attr('aria-expanded') === 'true' ) {
            $(this).attr('aria-expanded', 'false');
        } else {
            $(this).attr('aria-expanded', 'true');
        }
    });

    // $(document).click(function (e) {
    //     if (!$(menu_trigger).is(e.target)) {
    //         menu_trigger.removeClass('nav-toggle--active');
    //         nav.removeClass('navbar--active');
    //         body.removeClass('overflow--hidden');
    //         logo.removeClass('logo--white');
    //     }
    // });
})