jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
  };

$(function () {

    // Add class if edge
    if (/Edge/.test(navigator.userAgent)) {
        $('body').addClass('ie ie--edge');
    }

    // Add class if IE 11
    var is_ie11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    if (is_ie11) {
        
        $('body').addClass('ie ie--11');
    }

    // Add class if IE10
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    if (is_ie10) {
        $('body').addClass('ie ie--10');
    }

    // Add class if safari
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
        $('body').addClass('body--safari');
    }

    // // Remove empty paragraphs
    // $('p').filter(function(){ return $.trim(this.innerHTML)==="&nbsp;" }).remove();

    // /* ==== init ==== */
    // $('.lazy-load').Lazy({
    //     effect: 'fadeIn',
    //     effectTime: 300
    // });

    $('.owl-carousel').owlCarousel({
        items: 1,
        // loop:true,
        dots: true
        // nav:true,
    })

    /* === Add aria labels to owl carousel buttons === */ 
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });

    // Gallery
    $('.gallery__item').simpleLightbox();



    // $(".no-link").click(function (ev) {
    //     ev.preventDefault();
    // });

    // /* === Custom bullet list === */
    // $('.list-item--custom').closest('ul').addClass('ul--stripped');


    //$('.map-panel').lazyLoadGoogleMaps();


    /* ==== Cookie notice ==== */
   
    // var cookie = $('.cookie-notice');
  
    // if (Cookies.get('northCookie')) {
    //     cookie.hide();
    // } else {
    //     cookie.show();
    //     $('.cookie-notice__close').click(function () {
    //         Cookies.set('northCookie', 'accepted', {
    //             expires: 365
    //         });
    //         cookie.hide();
    //     });
    // }
});

// $(window).on('load', function () {
//     var jobRole = $("#jobRole").val();
//     var jobRef = $("#jobRef").val();
//     $("#1c9a869f-121f-4e84-e64a-a70ea8abba58").val(jobRef);
//     $("#24e14ec2-7255-4cfd-d11e-2083ac8e9b2f").val(jobRole);
    
//     // Remove loading window
//     setTimeout(function () {
//         $('.loading-overlay').addClass('loading-overlay--hide');
//         $('.homepage').addClass('homepage--active');
//         $('main').addClass('main--active');

//         // Re-run the polyfill on multiple elements
//         var elements = document.querySelectorAll('.main-banner__img');
//         objectFitPolyfill(elements);

//         elements2 = document.querySelectorAll('.panel__image');
//         objectFitPolyfill(elements2);

//     }, 500);

// });

